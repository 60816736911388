body {
  background-image:
    linear-gradient(to bottom, rgba(115,86,255,.40),rgba(153,204,255,.80),rgba(153,204,255,.01)),
    url('../public/image/mems-landing.svg');
  background-size: 100% 100% , cover;
  background-position: bottom;
  background-repeat: no-repeat;

  overflow: hidden;
  height: 100vh;
}

.lazyDog {
  font-family: "Lazydog";
}
.dm {
  font-family: "DMSans-Regular";
}

.main_div {
  overflow: hidden;
  height: 100vh;
  position: relative;
}

.stars{
  position: absolute;
  top: 5%;
  height: 20vh;
}

.div_second {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 80vh;
  width: 100vw;
}

.div_second_text1_para {
  position: relative;
  bottom: 1rem;
  font-size:300px;
  letter-spacing: -23px;
  color: #ffffff;
  text-align: center;
  margin: 0;
}

.div_second_text2_para {
  font-family: "Lazydog";
  position: relative;
  bottom: 4rem;
  color: #ffffff;
  font-size: 32px;
  z-index: 3;
  text-align: center;
}
.coming_soon_div{
  width: 356px;
  height: 53px;
  display: grid;
  place-content: center;
  background-color: #7459ff;
  cursor: pointer;
  position: relative;
  bottom: 4rem;
  border-radius: 50px;
  /* border: 1px solid #fff; */
}
.coming_soon_text {
  font-family: "Lazydog";
  font-size: 30px;
  color: #fff;
  letter-spacing: 2px;
}

.coming_soon_div:hover{
  background-color: #5334ec;
  transform: translateY(-0.10em);
  transition-duration: 300ms;
;
}
.the_image {
  width: 37vw;
  position:absolute;
  right: -9rem;
  bottom: -17rem;
  z-index: -1;
}

.social_div {
  position: absolute;
  bottom: 3rem;
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
}
.social_logo{
  height: 25px;
  cursor: pointer;
}
.social_logo:hover {
  transform: translateY(-0.10em);
  transition-duration: 300ms;
}


/* 4k display */
@media (min-width: 2460px) {
  .the_image {
    right: -15rem;
    bottom: -22rem;
  }
}


/* laptop low res */

@media (min-width: 1235px) and (max-width: 1367px) {
  .the_image {
    /* border: 1px solid green; */
    width: 37vw;
    position:absolute;
    right: -6rem;
    bottom: -14rem;
  }
  .div_images {
    bottom: -15rem;
    right: 22rem;
  }
  .div_second{
    transform: scale(0.7);
  }
}

/* laptop very low res */

@media (max-width: 1234px) and (min-width: 1024px){
  
  .the_image {
    width: 37vw;
    position:absolute;
    right: -6rem;
    bottom: -10rem;
    /* border: 1px solid pink */
  }
  .div_images {
    bottom: -15rem;
    right: 22rem;
  }
  .div_second{
    transform: scale(0.7);
  }
}


/* tab */
@media (min-height: 1024px) and (max-width: 1234px) {
  body {
    background-size: cover;
  }
  .image_div {
    overflow-x: hidden;
    /* border: 1px solid #fff; */
    position: absolute;
    right: 0;
    bottom:8rem;
  }
  .the_image {
    position: relative;
    width: 45vw;
    right: -6rem;
    bottom: auto;
    /* border: 1px solid yellow */
  }
  .div_images {
    bottom: -10rem;
    right: 10rem;
    width: 100%;
  }
  .div_second{
    transform: scale(0.7);
  }
}


/* mobile big+++ */
@media (max-width: 1024px) and (min-width: 641px) {  
  .image_div {
    overflow-x: hidden;
    /* border: 1px solid #fff; */
    position: absolute;
    right: 0;
    bottom:0rem;
  }
  .the_image {
    position: relative;
    width: 40vw;
    right: -5rem;
    bottom: auto;
    /* border: 1px solid rgb(128, 47, 0) */
  }
  .div_second{
    transform: scale(0.7);
  }
}

@media (max-width: 640px) {
  .image_div {
    overflow-x: hidden;
    /* border: 1px solid #fff; */
    position: absolute;
    right: 0;
    bottom:0rem;
  }
  .the_image {
    position: relative;
    width: 50vw;
    right: -4.5rem;
    bottom: auto;
    /* border: 1px solid rgb(128, 47, 0) */
  }
  .div_second{
    transform: scale(0.7);
  }
 }
@media (max-width: 500px) { 
  body {
    height: calc(100vh - calc(100vh - 100%));
    background-image:
    linear-gradient(to bottom, rgba(115,86,255,.90),rgba(153,204,255,.60),rgba(153,204,255,.95),rgba(153,204,255,1)),
    url('../public/image/mems-landing.svg');
    background-size: 100% 100vh,200rem 40rem;
  background-position:top center;
  background-repeat: no-repeat;
  }

  .main_div {
    height: 100%;
    position: static;
  }

  .stars{
    display: none;
  }
  
  .div_second {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    height: 55vh;
    width: 100vw;
  }
  
  .div_second_text1_para {
    position: relative;
    top: 4rem;
    letter-spacing: -10px;
    font-size: 7rem;
    color: #ffffff;
    text-align: center;
    margin: 0;
  }

  
  .div_second_text2_para {
    bottom: auto;
    color: #ffffff;
    font-size: 26px;
    padding: 37px;
    font-weight: 400;
    /* opacity: 65%; */
    text-align: center;
  }
  .coming_soon_div{
    width: 212.28px;
    height: 31px;
    display: grid;
    place-content: center;
    background-color: #7459ff;
    cursor: pointer;
    position: relative;
    bottom: 2rem;
    border-radius: 50px;
  }
  .coming_soon_text {
    font-family: "Lazydog";
    font-size: 20px;
    color: #fff;
    letter-spacing: 2px;
  }
  .image_div {
    overflow-x: hidden;
    /* border: 1px solid #fff; */
    position: absolute;
    right: 0;
    bottom:7rem;
  }
  .the_image {
    position: relative;
    width: 73vw;
    right: -4.5rem;
    bottom: auto;
    /* border: 1px solid rgb(128, 47, 0) */
  }

  .div_images {
    width: 100vw;
    bottom: 0rem;
    position: absolute;
    overflow: hidden;
  }
.social_logo{
  height: 20px;
}
.social_div {
  position: absolute;
  bottom: 3rem;
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
}
 }
 
 /* @media screen and (min-height: 300px) and (max-height: 550px) {
  .image_div {
    overflow-x: hidden;
    position: absolute;
    right: 0;
    bottom:4rem;
  }
  .the_image {
    position: relative;
    width: 63vw;
    right: -4rem;
    bottom: auto;
  }

 } */